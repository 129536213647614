import React from 'react';

import Block from '../components/Block';

const Sitewidth = props => (
  <Block className={props.className} m="auto" maxWidth="1200px" px={[4, 5, 6]}>
    {props.children}
  </Block>
);

export default Sitewidth;
