/**
 * Logo
 * ----
 * props:
 *  - size (default / small / large)
 */

import PropTypes from 'prop-types';

import styled from 'styled-components';
import styledProps from 'styled-props';
import { themeGet, space } from 'styled-system';
import themed from 'styled-theming';
import tag from 'clean-tag';

import { rem } from '../utils';

/**
 * Config
 * ------
 */

const conf = {
  font: {
    size: {
      default: rem(32),
      small: rem(28),
      large: rem(50),
    },

    weight: 900,
  },

  color: {
    main: themed('mode', {
      light: themeGet('colors.black.base'),
      dark: themeGet('colors.white.base'),
      color: themeGet('colors.white.base'),
    }),

    suffix: themed('mode', {
      light: themeGet('colors.black.shade.1'),
      dark: themeGet('colors.white.shade.1'),
      color: themeGet('colors.black.shade.0'),
    }),
  },
};

/**
 * Logo
 * ----
 * Main component.
 */

const Logo = styled(tag)`
  ${space};
  position: relative;

  font-size: ${styledProps(conf.font.size, 'size')};
  font-weight: ${conf.font.weight};

  &:before {
    content: "thehive";
    position: relative;
    color: ${conf.color.main};
  }

  &:after {
    content: ".rs";
    position: relative;
    color: ${conf.color.suffix};
  }
`;

Logo.propTypes = {
  size: PropTypes.oneOf(['default', 'small', 'large']),
};

Logo.defaultProps = {
  size: 'default',
};

export default Logo;
