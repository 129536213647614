/**
 * Text
 * ----
 * props:
 *  - fontSize ([index])
 *  - fontWeight ([index])
 */

import styled from 'styled-components';
import {
  themeGet,
  space,
  fontWeight,
  fontSize,
  textAlign,
  maxWidth,
  color,
} from 'styled-system';
import themed from 'styled-theming';
import tag from 'clean-tag';

import { antialias, noJitter } from '../utils';

/**
 * Config
 * ------
 */

const conf = {
  line: { // line-height
    p: 1.5,
    h: 1.2,
  },
  color: { // color
    p: themed('mode', {
      light: 'hsl(0, 0%, 60%)', // @TODO normalize this (emoji color quickfix).
      dark: themeGet('colors.white.shade.2'),
      color: themeGet('colors.white.base'),
    }),
    h: themed('mode', {
      light: themeGet('colors.black.base'),
      dark: themeGet('colors.white.base'),
      color: themeGet('colors.white.base'),
    }),
  },
};

/**
 * Text
 * ----
 * Main component.
 */

const Text = styled(tag)`
  ${antialias};
  ${noJitter};

  position: relative;
  user-select: text;

  line-height: ${props => (props.is === 'p' || props.is === 'div'
    ? conf.line.p : conf.line.h
  )};
  color: ${props => (props.is === 'p' || props.is === 'div'
    ? conf.color.p : conf.color.h
  )};

  ${space}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${maxWidth}
  ${color}
`;

Text.defaultProps = {
  is: 'p',
};


export default Text;
