import React from 'react';

import styled from 'styled-components';
import { top, left, height, themeGet } from 'styled-system';

import { random, position, size, ease } from '../utils';

import hexagonParticle from '../assets/graphics/hexagon-particle.svg';

const Particle = styled.div`
  position: absolute;
  ${size('86px')};

  ${top};
  ${left};

  opacity: 0.03;

  animation: float infinite alternate ${ease('inOut', 'quad')};
  animation-duration: ${props => props.duration}ms;
  animation-delay: ${props => props.delay}ms;
  @keyframes float { 100% { transform: translateY(50%); } }

  &:after {
    content: "";
    ${position('absolute', 0)}
    background: url(${hexagonParticle}) no-repeat center;
    background-size: contain;

    transform: rotate(${props => props.rotation}deg);
  }

  @media (max-width: ${themeGet('breakpoints.1')}) {
    display: none;
  }
`;

const particlesList = [
  { top: ' 5%', left: '45%' },
  { top: '15%', left: '85%' },
  { top: '30%', left: '75%' },
  { top: '35%', left: '30%' },
  { top: '45%', left: ' 0%' },
  { top: '48%', left: '92%' },
  { top: ['50%', null, null, null, '70%'],
    left: ['58%', null, null, null, '40%'] },
  { top: ['64%', null, null, null, '78%'],
    left: ['74%', null, null, null, '70%'] },
];

const Particles = props => (
  <div className={props.className}>
    {particlesList.map((particle, i) => (
      <Particle
        top={particle.top}
        left={particle.left}
        rotation={random(-45, 45)}
        duration={random(1500, 3000)}
        delay={random(0, 1000)}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
      />
    ))}
  </div>
);

const StyledParticles = styled(Particles)`
  position: absolute;
  top: 0;
  left: 50%;
  width: 1200px;
  transform: translateX(-50%);
  z-index: 1500;
  pointer-events: none;

  ${height};
`;

export default StyledParticles;
