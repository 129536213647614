/**
 * Theme
 * -----
 */

import color from 'tinycolor2';
import { rem } from '../utils';

const breakpoints = [
  rem('380px'), rem('768px'), // mobile
  rem('1024px'), rem('1280px'), rem('1680px'), rem('1920px'), // desktop
];

const siteWidth = '1200px';

// color config
const white = 'hsl(0, 0%, 100%)';
const black = 'hsl(0, 0%, 15%)';
const brand = 'hsl(42, 100%, 55%)';

// color mixins
const colors = {
  white: {
    base: white,
    shade: [
      color(white).setAlpha(0.85).toHslString(),
      color(white).setAlpha(0.60).toHslString(),
      color(white).setAlpha(0.50).toHslString(),
      color(white).setAlpha(0.30).toHslString(),
      color(white).setAlpha(0.10).toHslString(),
    ],
  },
  black: {
    base: black,
    shade: [
      color(black).setAlpha(0.85).toHslString(),
      color(black).setAlpha(0.60).toHslString(),
      color(black).setAlpha(0.50).toHslString(),
      color(black).setAlpha(0.30).toHslString(),
      color(black).setAlpha(0.10).toHslString(),
    ],
  },
  brand: {
    base: brand,
    gradient: `linear-gradient(to right,
      ${color(brand).darken(0.3).spin(-8).toHslString()},
      ${color(brand).lighten(0.3).spin(8).toHslString()}
    )`,
  },
};

const space = [
  rem(3), rem(5), rem(8), rem(13), rem(21), rem(34),
  rem(55), rem(89), rem(144), rem(233), rem(377), rem(610),
];

const fontFamilies = {
  default: `
    'Montserrat',
    'Avenir Next', 'Helvetica Neue', 'Helvetica',
    'sans-serif'
  `,
};

const fontSizes = [
  rem(14), rem(16), rem(18), rem(20), rem(22), rem(24), rem(26),
  rem(32), rem(34), rem(38), rem(50),
];

const fontWeights = {
  light: 300,
  regular: 400,
  bold: 600,
  heavy: 900,
};

const radii = [
  rem(0), rem(2), rem(4), rem(8), rem(16), rem(32),
];

const theme = {
  breakpoints,
  siteWidth,
  colors,
  space,
  fontFamilies,
  fontSizes,
  fontWeights,
  radii,
};

export default theme;
