import React from 'react';

import styled, { css } from 'styled-components';

import Parallax from '../components/Parallax';
import { random } from '../utils';

/* eslint-disable max-len */

const Hexagons = props => (
  <div className={props.className}>
    <Parallax depth={0.2} align="bottom">
      <svg width="2560px" height="1455px" viewBox="0 0 2560 1455" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <g transform="translate(0.000000, -129.000000)" fill="#191919" stroke="#FFFFFF" strokeWidth={2}>
            <g transform="translate(1302.337028, 951.863809) rotate(-10.000000) translate(-1302.337028, -951.863809) translate(-184.162972, 179.363809)">
              <path d="M1824.44249,499.199052 C1821.6734,497.600316 1818.26174,497.600316 1815.49265,499.199052 L1729.47492,548.861408 C1726.70583,550.460144 1725,553.41473 1725,556.612202 L1725,655.936913 C1725,659.134386 1726.70583,662.088972 1729.47492,663.687708 L1815.49265,713.350064 C1818.26174,714.9488 1821.6734,714.9488 1824.44249,713.350064 L1910.46022,663.687708 C1913.22931,662.088972 1914.93514,659.134386 1914.93514,655.936913 L1914.93514,556.612202 C1914.93514,553.41473 1913.22931,550.460144 1910.46022,548.861408 L1824.44249,499.199052 Z" />
              <path d="M2783.61855,167.927805 C2780.84945,166.329069 2777.43779,166.329069 2774.6687,167.927805 L2688.65098,217.59016 C2685.88188,219.188897 2684.17605,222.143483 2684.17605,225.340955 L2684.17605,324.665666 C2684.17605,327.863139 2685.88188,330.817725 2688.65098,332.416461 L2774.6687,382.078816 C2777.43779,383.677553 2780.84945,383.677553 2783.61855,382.078816 L2869.63627,332.416461 C2872.40536,330.817725 2874.11119,327.863139 2874.11119,324.665666 L2874.11119,225.340955 C2874.11119,222.143483 2872.40536,219.188897 2869.63627,217.59016 L2783.61855,167.927805 Z" />
              <path d="M579.616141,333.214244 C576.847049,331.615508 573.435387,331.615508 570.666295,333.214244 L484.648572,382.8766 C481.879479,384.475336 480.173648,387.429922 480.173648,390.627395 L480.173648,489.952106 C480.173648,493.149578 481.879479,496.104164 484.648572,497.7029 L570.666295,547.365256 C573.435387,548.963992 576.847049,548.963992 579.616141,547.365256 L665.633864,497.7029 C668.402957,496.104164 670.108788,493.149578 670.108788,489.952106 L670.108788,390.627395 C670.108788,387.429922 668.402957,384.475336 665.633864,382.8766 L579.616141,333.214244 Z" />
              <path d="M1920.44249,334.199052 C1917.6734,332.600316 1914.26174,332.600316 1911.49265,334.199052 L1825.47492,383.861408 C1822.70583,385.460144 1821,388.41473 1821,391.612202 L1821,490.936913 C1821,494.134386 1822.70583,497.088972 1825.47492,498.687708 L1911.49265,548.350064 C1914.26174,549.9488 1917.6734,549.9488 1920.44249,548.350064 L2006.46022,498.687708 C2009.22931,497.088972 2010.93514,494.134386 2010.93514,490.936913 L2010.93514,391.612202 C2010.93514,388.41473 2009.22931,385.460144 2006.46022,383.861408 L1920.44249,334.199052 Z" />
              <path d="M2881.07748,332.790871 C2878.30839,331.192134 2874.89672,331.192134 2872.12763,332.790871 L2786.10991,382.453226 C2783.34082,384.051962 2781.63499,387.006548 2781.63499,390.204021 L2781.63499,489.528732 C2781.63499,492.726204 2783.34082,495.68079 2786.10991,497.279527 L2872.12763,546.941882 C2874.89672,548.540618 2878.30839,548.540618 2881.07748,546.941882 L2967.0952,497.279527 C2969.86429,495.68079 2971.57013,492.726204 2971.57013,489.528732 L2971.57013,390.204021 C2971.57013,387.006548 2969.86429,384.051962 2967.0952,382.453226 L2881.07748,332.790871 Z" />
              <path d="M1920.44249,665.199052 C1917.6734,663.600316 1914.26174,663.600316 1911.49265,665.199052 L1825.47492,714.861408 C1822.70583,716.460144 1821,719.41473 1821,722.612202 L1821,821.936913 C1821,825.134386 1822.70583,828.088972 1825.47492,829.687708 L1911.49265,879.350064 C1914.26174,880.9488 1917.6734,880.9488 1920.44249,879.350064 L2006.46022,829.687708 C2009.22931,828.088972 2010.93514,825.134386 2010.93514,821.936913 L2010.93514,722.612202 C2010.93514,719.41473 2009.22931,716.460144 2006.46022,714.861408 L1920.44249,665.199052 Z" />
              <path d="M675.616141,499.214244 C672.847049,497.615508 669.435387,497.615508 666.666295,499.214244 L580.648572,548.8766 C577.879479,550.475336 576.173648,553.429922 576.173648,556.627395 L576.173648,655.952106 C576.173648,659.149578 577.879479,662.104164 580.648572,663.7029 L666.666295,713.365256 C669.435387,714.963992 672.847049,714.963992 675.616141,713.365256 L761.633864,663.7029 C764.402957,662.104164 766.108788,659.149578 766.108788,655.952106 L766.108788,556.627395 C766.108788,553.429922 764.402957,550.475336 761.633864,548.8766 L675.616141,499.214244 Z" />
              <path d="M1441.44249,168.199052 C1438.6734,166.600316 1435.26174,166.600316 1432.49265,168.199052 L1346.47492,217.861408 C1343.70583,219.460144 1342,222.41473 1342,225.612202 L1342,324.936913 C1342,328.134386 1343.70583,331.088972 1346.47492,332.687708 L1432.49265,382.350064 C1435.26174,383.9488 1438.6734,383.9488 1441.44249,382.350064 L1527.46022,332.687708 C1530.22931,331.088972 1531.93514,328.134386 1531.93514,324.936913 L1531.93514,225.612202 C1531.93514,222.41473 1530.22931,219.460144 1527.46022,217.861408 L1441.44249,168.199052 Z" />
              <path d="M580.860338,3.57332422 C578.091246,1.97458804 574.679584,1.97458804 571.910491,3.57332422 L485.892768,53.2356797 C483.123676,54.8344159 481.417845,57.7890022 481.417845,60.9864745 L481.417845,160.311185 C481.417845,163.508658 483.123676,166.463244 485.892768,168.06198 L571.910491,217.724336 C574.679584,219.323072 578.091246,219.323072 580.860338,217.724336 L666.878061,168.06198 C669.647153,166.463244 671.352985,163.508658 671.352985,160.311185 L671.352985,60.9864745 C671.352985,57.7890022 669.647153,54.8344159 666.878061,53.2356797 L580.860338,3.57332422 Z" />
              <path d="M676.103856,169.069697 C673.334763,167.470961 669.923101,167.470961 667.154009,169.069697 L581.136286,218.732053 C578.367194,220.330789 576.661362,223.285375 576.661362,226.482848 L576.661362,325.807559 C576.661362,329.005031 578.367194,331.959617 581.136286,333.558353 L667.154009,383.220709 C669.923101,384.819445 673.334763,384.819445 676.103856,383.220709 L762.121579,333.558353 C764.890671,331.959617 766.596502,329.005031 766.596502,325.807559 L766.596502,226.482848 C766.596502,223.285375 764.890671,220.330789 762.121579,218.732053 L676.103856,169.069697 Z" />
              <path d="M1536.44249,3.19905213 C1533.6734,1.60031596 1530.26174,1.60031596 1527.49265,3.19905213 L1441.47492,52.8614076 C1438.70583,54.4601438 1437,57.4147301 1437,60.6122024 L1437,159.936913 C1437,163.134386 1438.70583,166.088972 1441.47492,167.687708 L1527.49265,217.350064 C1530.26174,218.9488 1533.6734,218.9488 1536.44249,217.350064 L1622.46022,167.687708 C1625.22931,166.088972 1626.93514,163.134386 1626.93514,159.936913 L1626.93514,60.6122024 C1626.93514,57.4147301 1625.22931,54.4601438 1622.46022,52.8614076 L1536.44249,3.19905213 Z" />
              <path d="M1057.44249,499.199052 C1054.6734,497.600316 1051.26174,497.600316 1048.49265,499.199052 L962.474923,548.861408 C959.705831,550.460144 958,553.41473 958,556.612202 L958,655.936913 C958,659.134386 959.705831,662.088972 962.474923,663.687708 L1048.49265,713.350064 C1051.26174,714.9488 1054.6734,714.9488 1057.44249,713.350064 L1143.46022,663.687708 C1146.22931,662.088972 1147.93514,659.134386 1147.93514,655.936913 L1147.93514,556.612202 C1147.93514,553.41473 1146.22931,550.460144 1143.46022,548.861408 L1057.44249,499.199052 Z" />
              <path d="M100.618392,499.209674 C97.8492993,497.610937 94.4376369,497.610937 91.6685446,499.209674 L5.65082168,548.872029 C2.8817294,550.470765 1.17589822,553.425352 1.17589822,556.622824 L1.17589822,655.947535 C1.17589822,659.145007 2.8817294,662.099593 5.65082168,663.69833 L91.6685446,713.360685 C94.4376369,714.959421 97.8492993,714.959421 100.618392,713.360685 L186.636114,663.69833 C189.405207,662.099593 191.111038,659.145007 191.111038,655.947535 L191.111038,556.622824 C191.111038,553.425352 189.405207,550.470765 186.636114,548.872029 L100.618392,499.209674 Z" />
              <path d="M1632.44249,499.199052 C1629.6734,497.600316 1626.26174,497.600316 1623.49265,499.199052 L1537.47492,548.861408 C1534.70583,550.460144 1533,553.41473 1533,556.612202 L1533,655.936913 C1533,659.134386 1534.70583,662.088972 1537.47492,663.687708 L1623.49265,713.350064 C1626.26174,714.9488 1629.6734,714.9488 1632.44249,713.350064 L1718.46022,663.687708 C1721.22931,662.088972 1722.93514,659.134386 1722.93514,655.936913 L1722.93514,556.612202 C1722.93514,553.41473 1721.22931,550.460144 1718.46022,548.861408 L1632.44249,499.199052 Z" />
              <path d="M2591.61855,167.927805 C2588.84945,166.329069 2585.43779,166.329069 2582.6687,167.927805 L2496.65098,217.59016 C2493.88188,219.188897 2492.17605,222.143483 2492.17605,225.340955 L2492.17605,324.665666 C2492.17605,327.863139 2493.88188,330.817725 2496.65098,332.416461 L2582.6687,382.078816 C2585.43779,383.677553 2588.84945,383.677553 2591.61855,382.078816 L2677.63627,332.416461 C2680.40536,330.817725 2682.11119,327.863139 2682.11119,324.665666 L2682.11119,225.340955 C2682.11119,222.143483 2680.40536,219.188897 2677.63627,217.59016 L2591.61855,167.927805 Z" />
              <path d="M388.616141,333.214244 C385.847049,331.615508 382.435387,331.615508 379.666295,333.214244 L293.648572,382.8766 C290.879479,384.475336 289.173648,387.429922 289.173648,390.627395 L289.173648,489.952106 C289.173648,493.149578 290.879479,496.104164 293.648572,497.7029 L379.666295,547.365256 C382.435387,548.963992 385.847049,548.963992 388.616141,547.365256 L474.633864,497.7029 C477.402957,496.104164 479.108788,493.149578 479.108788,489.952106 L479.108788,390.627395 C479.108788,387.429922 477.402957,384.475336 474.633864,382.8766 L388.616141,333.214244 Z" />
              <path d="M1728.44249,665.199052 C1725.6734,663.600316 1722.26174,663.600316 1719.49265,665.199052 L1633.47492,714.861408 C1630.70583,716.460144 1629,719.41473 1629,722.612202 L1629,821.936913 C1629,825.134386 1630.70583,828.088972 1633.47492,829.687708 L1719.49265,879.350064 C1722.26174,880.9488 1725.6734,880.9488 1728.44249,879.350064 L1814.46022,829.687708 C1817.22931,828.088972 1818.93514,825.134386 1818.93514,821.936913 L1818.93514,722.612202 C1818.93514,719.41473 1817.22931,716.460144 1814.46022,714.861408 L1728.44249,665.199052 Z" />
              <path d="M1249.44249,499.199052 C1246.6734,497.600316 1243.26174,497.600316 1240.49265,499.199052 L1154.47492,548.861408 C1151.70583,550.460144 1150,553.41473 1150,556.612202 L1150,655.936913 C1150,659.134386 1151.70583,662.088972 1154.47492,663.687708 L1240.49265,713.350064 C1243.26174,714.9488 1246.6734,714.9488 1249.44249,713.350064 L1335.46022,663.687708 C1338.22931,662.088972 1339.93514,659.134386 1339.93514,655.936913 L1339.93514,556.612202 C1339.93514,553.41473 1338.22931,550.460144 1335.46022,548.861408 L1249.44249,499.199052 Z" />
              <path d="M2591.08241,829.604992 C2588.31332,828.006256 2584.90165,828.006256 2582.13256,829.604992 L2496.11484,879.267348 C2493.34575,880.866084 2491.63992,883.82067 2491.63992,887.018142 L2491.63992,986.342853 C2491.63992,989.540326 2493.34575,992.494912 2496.11484,994.093648 L2582.13256,1043.756 C2584.90165,1045.35474 2588.31332,1045.35474 2591.08241,1043.756 L2677.10013,994.093648 C2679.86922,992.494912 2681.57506,989.540326 2681.57506,986.342853 L2681.57506,887.018142 C2681.57506,883.82067 2679.86922,880.866084 2677.10013,879.267348 L2591.08241,829.604992 Z" />
              <path d="M292.618392,499.209674 C289.849299,497.610937 286.437637,497.610937 283.668545,499.209674 L197.650822,548.872029 C194.881729,550.470765 193.175898,553.425352 193.175898,556.622824 L193.175898,655.947535 C193.175898,659.145007 194.881729,662.099593 197.650822,663.69833 L283.668545,713.360685 C286.437637,714.959421 289.849299,714.959421 292.618392,713.360685 L378.636114,663.69833 C381.405207,662.099593 383.111038,659.145007 383.111038,655.947535 L383.111038,556.622824 C383.111038,553.425352 381.405207,550.470765 378.636114,548.872029 L292.618392,499.209674 Z" />
              <path d="M1345.44249,3.19905213 C1342.6734,1.60031596 1339.26174,1.60031596 1336.49265,3.19905213 L1250.47492,52.8614076 C1247.70583,54.4601438 1246,57.4147301 1246,60.6122024 L1246,159.936913 C1246,163.134386 1247.70583,166.088972 1250.47492,167.687708 L1336.49265,217.350064 C1339.26174,218.9488 1342.6734,218.9488 1345.44249,217.350064 L1431.46022,167.687708 C1434.22931,166.088972 1435.93514,163.134386 1435.93514,159.936913 L1435.93514,60.6122024 C1435.93514,57.4147301 1434.22931,54.4601438 1431.46022,52.8614076 L1345.44249,3.19905213 Z" />
              <path d="M390.996389,3.14778382 C388.227297,1.54904764 384.815635,1.54904764 382.046542,3.14778382 L296.028819,52.8101393 C293.259727,54.4088755 291.553896,57.3634618 291.553896,60.5609341 L291.553896,159.885645 C291.553896,163.083117 293.259727,166.037704 296.028819,167.63644 L382.046542,217.298795 C384.815635,218.897532 388.227297,218.897532 390.996389,217.298795 L477.014112,167.63644 C479.783204,166.037704 481.489036,163.083117 481.489036,159.885645 L481.489036,60.5609341 C481.489036,57.3634618 479.783204,54.4088755 477.014112,52.8101393 L390.996389,3.14778382 Z" />
              <path d="M1345.44249,665.199052 C1342.6734,663.600316 1339.26174,663.600316 1336.49265,665.199052 L1250.47492,714.861408 C1247.70583,716.460144 1246,719.41473 1246,722.612202 L1246,821.936913 C1246,825.134386 1247.70583,828.088972 1250.47492,829.687708 L1336.49265,879.350064 C1339.26174,880.9488 1342.6734,880.9488 1345.44249,879.350064 L1431.46022,829.687708 C1434.22931,828.088972 1435.93514,825.134386 1435.93514,821.936913 L1435.93514,722.612202 C1435.93514,719.41473 1434.22931,716.460144 1431.46022,714.861408 L1345.44249,665.199052 Z" />
              <path d="M2687.08241,995.604992 C2684.31332,994.006256 2680.90165,994.006256 2678.13256,995.604992 L2592.11484,1045.26735 C2589.34575,1046.86608 2587.63992,1049.82067 2587.63992,1053.01814 L2587.63992,1152.34285 C2587.63992,1155.54033 2589.34575,1158.49491 2592.11484,1160.09365 L2678.13256,1209.756 C2680.90165,1211.35474 2684.31332,1211.35474 2687.08241,1209.756 L2773.10013,1160.09365 C2775.86922,1158.49491 2777.57506,1155.54033 2777.57506,1152.34285 L2777.57506,1053.01814 C2777.57506,1049.82067 2775.86922,1046.86608 2773.10013,1045.26735 L2687.08241,995.604992 Z" />
              <path d="M388.618392,664.209674 C385.849299,662.610937 382.437637,662.610937 379.668545,664.209674 L293.650822,713.872029 C290.881729,715.470765 289.175898,718.425352 289.175898,721.622824 L289.175898,820.947535 C289.175898,824.145007 290.881729,827.099593 293.650822,828.69833 L379.668545,878.360685 C382.437637,879.959421 385.849299,879.959421 388.618392,878.360685 L474.636114,828.69833 C477.405207,827.099593 479.111038,824.145007 479.111038,820.947535 L479.111038,721.622824 C479.111038,718.425352 477.405207,715.470765 474.636114,713.872029 L388.618392,664.209674 Z" />
              <path d="M866.105437,499.384719 C863.336345,497.785983 859.924682,497.785983 857.15559,499.384719 L771.137867,549.047074 C768.368775,550.64581 766.662944,553.600397 766.662944,556.797869 L766.662944,656.12258 C766.662944,659.320052 768.368775,662.274639 771.137867,663.873375 L857.15559,713.53573 C859.924682,715.134466 863.336345,715.134466 866.105437,713.53573 L952.12316,663.873375 C954.892252,662.274639 956.598083,659.320052 956.598083,656.12258 L956.598083,556.797869 C956.598083,553.600397 954.892252,550.64581 952.12316,549.047074 L866.105437,499.384719 Z" />
              <path d="M961.936909,333.949336 C959.167816,332.3506 955.756154,332.3506 952.987062,333.949336 L866.969339,383.611691 C864.200247,385.210428 862.494415,388.165014 862.494415,391.362486 L862.494415,490.687197 C862.494415,493.884669 864.200247,496.839256 866.969339,498.437992 L952.987062,548.100347 C955.756154,549.699084 959.167816,549.699084 961.936909,548.100347 L1047.95463,498.437992 C1050.72372,496.839256 1052.42956,493.884669 1052.42956,490.687197 L1052.42956,391.362486 C1052.42956,388.165014 1050.72372,385.210428 1047.95463,383.611691 L961.936909,333.949336 Z" />
              <path d="M1920.51637,1327.05411 C1917.74728,1325.45537 1914.33562,1325.45537 1911.56652,1327.05411 L1825.5488,1376.71647 C1822.77971,1378.3152 1821.07388,1381.26979 1821.07388,1384.46726 L1821.07388,1483.79197 C1821.07388,1486.98944 1822.77971,1489.94403 1825.5488,1491.54277 L1911.56652,1541.20512 C1914.33562,1542.80386 1917.74728,1542.80386 1920.51637,1541.20512 L2006.53409,1491.54277 C2009.30319,1489.94403 2011.00902,1486.98944 2011.00902,1483.79197 L2011.00902,1384.46726 C2011.00902,1381.26979 2009.30319,1378.3152 2006.53409,1376.71647 L1920.51637,1327.05411 Z" />
              <path d="M1824.44249,1161.19905 C1821.6734,1159.60032 1818.26174,1159.60032 1815.49265,1161.19905 L1729.47492,1210.86141 C1726.70583,1212.46014 1725,1215.41473 1725,1218.6122 L1725,1317.93691 C1725,1321.13439 1726.70583,1324.08897 1729.47492,1325.68771 L1815.49265,1375.35006 C1818.26174,1376.9488 1821.6734,1376.9488 1824.44249,1375.35006 L1910.46022,1325.68771 C1913.22931,1324.08897 1914.93514,1321.13439 1914.93514,1317.93691 L1914.93514,1218.6122 C1914.93514,1215.41473 1913.22931,1212.46014 1910.46022,1210.86141 L1824.44249,1161.19905 Z" />
              <path d="M2304.07732,1326.79267 C2301.30823,1325.19393 2297.89657,1325.19393 2295.12747,1326.79267 L2209.10975,1376.45503 C2206.34066,1378.05376 2204.63483,1381.00835 2204.63483,1384.20582 L2204.63483,1483.53053 C2204.63483,1486.728 2206.34066,1489.68259 2209.10975,1491.28133 L2295.12747,1540.94368 C2297.89657,1542.54242 2301.30823,1542.54242 2304.07732,1540.94368 L2390.09504,1491.28133 C2392.86414,1489.68259 2394.56997,1486.728 2394.56997,1483.53053 L2394.56997,1384.20582 C2394.56997,1381.00835 2392.86414,1378.05376 2390.09504,1376.45503 L2304.07732,1326.79267 Z" />
              <path d="M2207.44249,1161.19905 C2204.6734,1159.60032 2201.26174,1159.60032 2198.49265,1161.19905 L2112.47492,1210.86141 C2109.70583,1212.46014 2108,1215.41473 2108,1218.6122 L2108,1317.93691 C2108,1321.13439 2109.70583,1324.08897 2112.47492,1325.68771 L2198.49265,1375.35006 C2201.26174,1376.9488 2204.6734,1376.9488 2207.44249,1375.35006 L2293.46022,1325.68771 C2296.22931,1324.08897 2297.93514,1321.13439 2297.93514,1317.93691 L2297.93514,1218.6122 C2297.93514,1215.41473 2296.22931,1212.46014 2293.46022,1210.86141 L2207.44249,1161.19905 Z" />
              <path d="M2495.28404,1328.01021 C2492.51495,1326.41148 2489.10328,1326.41148 2486.33419,1328.01021 L2400.31647,1377.67257 C2397.54738,1379.2713 2395.84154,1382.22589 2395.84154,1385.42336 L2395.84154,1484.74807 C2395.84154,1487.94555 2397.54738,1490.90013 2400.31647,1492.49887 L2486.33419,1542.16122 C2489.10328,1543.75996 2492.51495,1543.75996 2495.28404,1542.16122 L2581.30176,1492.49887 C2584.07085,1490.90013 2585.77668,1487.94555 2585.77668,1484.74807 L2585.77668,1385.42336 C2585.77668,1382.22589 2584.07085,1379.2713 2581.30176,1377.67257 L2495.28404,1328.01021 Z" />
              <path d="M1920.44249,995.199052 C1917.6734,993.600316 1914.26174,993.600316 1911.49265,995.199052 L1825.47492,1044.86141 C1822.70583,1046.46014 1821,1049.41473 1821,1052.6122 L1821,1151.93691 C1821,1155.13439 1822.70583,1158.08897 1825.47492,1159.68771 L1911.49265,1209.35006 C1914.26174,1210.9488 1917.6734,1210.9488 1920.44249,1209.35006 L2006.46022,1159.68771 C2009.22931,1158.08897 2010.93514,1155.13439 2010.93514,1151.93691 L2010.93514,1052.6122 C2010.93514,1049.41473 2009.22931,1046.46014 2006.46022,1044.86141 L1920.44249,995.199052 Z" />
              <path d="M1441.09427,1161.12625 C1438.32518,1159.52751 1434.91351,1159.52751 1432.14442,1161.12625 L1346.1267,1210.78861 C1343.35761,1212.38734 1341.65177,1215.34193 1341.65177,1218.5394 L1341.65177,1317.86411 C1341.65177,1321.06158 1343.35761,1324.01617 1346.1267,1325.61491 L1432.14442,1375.27726 C1434.91351,1376.876 1438.32518,1376.876 1441.09427,1375.27726 L1527.11199,1325.61491 C1529.88108,1324.01617 1531.58691,1321.06158 1531.58691,1317.86411 L1531.58691,1218.5394 C1531.58691,1215.34193 1529.88108,1212.38734 1527.11199,1210.78861 L1441.09427,1161.12625 Z" />
              <path d="M1441.09427,830.255484 C1438.32518,828.656748 1434.91351,828.656748 1432.14442,830.255484 L1346.1267,879.91784 C1343.35761,881.516576 1341.65177,884.471162 1341.65177,887.668635 L1341.65177,986.993346 C1341.65177,990.190818 1343.35761,993.145404 1346.1267,994.74414 L1432.14442,1044.4065 C1434.91351,1046.00523 1438.32518,1046.00523 1441.09427,1044.4065 L1527.11199,994.74414 C1529.88108,993.145404 1531.58691,990.190818 1531.58691,986.993346 L1531.58691,887.668635 C1531.58691,884.471162 1529.88108,881.516576 1527.11199,879.91784 L1441.09427,830.255484 Z" />
              <path d="M1536.44249,995.199052 C1533.6734,993.600316 1530.26174,993.600316 1527.49265,995.199052 L1441.47492,1044.86141 C1438.70583,1046.46014 1437,1049.41473 1437,1052.6122 L1437,1151.93691 C1437,1155.13439 1438.70583,1158.08897 1441.47492,1159.68771 L1527.49265,1209.35006 C1530.26174,1210.9488 1533.6734,1210.9488 1536.44249,1209.35006 L1622.46022,1159.68771 C1625.22931,1158.08897 1626.93514,1155.13439 1626.93514,1151.93691 L1626.93514,1052.6122 C1626.93514,1049.41473 1625.22931,1046.46014 1622.46022,1044.86141 L1536.44249,995.199052 Z" />
              <path d="M2016.07732,1160.79267 C2013.30823,1159.19393 2009.89657,1159.19393 2007.12747,1160.79267 L1921.10975,1210.45503 C1918.34066,1212.05376 1916.63483,1215.00835 1916.63483,1218.20582 L1916.63483,1317.53053 C1916.63483,1320.728 1918.34066,1323.68259 1921.10975,1325.28133 L2007.12747,1374.94368 C2009.89657,1376.54242 2013.30823,1376.54242 2016.07732,1374.94368 L2102.09504,1325.28133 C2104.86414,1323.68259 2106.56997,1320.728 2106.56997,1317.53053 L2106.56997,1218.20582 C2106.56997,1215.00835 2104.86414,1212.05376 2102.09504,1210.45503 L2016.07732,1160.79267 Z" />
              <path d="M579.910486,994.989769 C577.141393,993.391033 573.729731,993.391033 570.960639,994.989769 L484.942916,1044.65212 C482.173824,1046.25086 480.467992,1049.20545 480.467992,1052.40292 L480.467992,1151.72763 C480.467992,1154.9251 482.173824,1157.87969 484.942916,1159.47843 L570.960639,1209.14078 C573.729731,1210.73952 577.141393,1210.73952 579.910486,1209.14078 L665.928209,1159.47843 C668.697301,1157.87969 670.403132,1154.9251 670.403132,1151.72763 L670.403132,1052.40292 C670.403132,1049.20545 668.697301,1046.25086 665.928209,1044.65212 L579.910486,994.989769 Z" />
              <path d="M1536.51637,1327.05411 C1533.74728,1325.45537 1530.33562,1325.45537 1527.56652,1327.05411 L1441.5488,1376.71647 C1438.77971,1378.3152 1437.07388,1381.26979 1437.07388,1384.46726 L1437.07388,1483.79197 C1437.07388,1486.98944 1438.77971,1489.94403 1441.5488,1491.54277 L1527.56652,1541.20512 C1530.33562,1542.80386 1533.74728,1542.80386 1536.51637,1541.20512 L1622.53409,1491.54277 C1625.30319,1489.94403 1627.00902,1486.98944 1627.00902,1483.79197 L1627.00902,1384.46726 C1627.00902,1381.26979 1625.30319,1378.3152 1622.53409,1376.71647 L1536.51637,1327.05411 Z" />
              <path d="M1057.44249,830.199052 C1054.6734,828.600316 1051.26174,828.600316 1048.49265,830.199052 L962.474923,879.861408 C959.705831,881.460144 958,884.41473 958,887.612202 L958,986.936913 C958,990.134386 959.705831,993.088972 962.474923,994.687708 L1048.49265,1044.35006 C1051.26174,1045.9488 1054.6734,1045.9488 1057.44249,1044.35006 L1143.46022,994.687708 C1146.22931,993.088972 1147.93514,990.134386 1147.93514,986.936913 L1147.93514,887.612202 C1147.93514,884.41473 1146.22931,881.460144 1143.46022,879.861408 L1057.44249,830.199052 Z" />
              <path d="M484.407417,829.32019 C481.638324,827.721454 478.226662,827.721454 475.45757,829.32019 L389.439847,878.982545 C386.670754,880.581281 384.964923,883.535868 384.964923,886.73334 L384.964923,986.058051 C384.964923,989.255523 386.670754,992.21011 389.439847,993.808846 L475.45757,1043.4712 C478.226662,1045.06994 481.638324,1045.06994 484.407417,1043.4712 L570.42514,993.808846 C573.194232,992.21011 574.900063,989.255523 574.900063,986.058051 L574.900063,886.73334 C574.900063,883.535868 573.194232,880.581281 570.42514,878.982545 L484.407417,829.32019 Z" />
              <path d="M1153.59985,995.690867 C1150.83076,994.092131 1147.4191,994.092131 1144.65001,995.690867 L1058.63228,1045.35322 C1055.86319,1046.95196 1054.15736,1049.90655 1054.15736,1053.10402 L1054.15736,1152.42873 C1054.15736,1155.6262 1055.86319,1158.58079 1058.63228,1160.17952 L1144.65001,1209.84188 C1147.4191,1211.44062 1150.83076,1211.44062 1153.59985,1209.84188 L1239.61758,1160.17952 C1242.38667,1158.58079 1244.0925,1155.6262 1244.0925,1152.42873 L1244.0925,1053.10402 C1244.0925,1049.90655 1242.38667,1046.95196 1239.61758,1045.35322 L1153.59985,995.690867 Z" />
              <path d="M770.442493,334.199052 C767.673401,332.600316 764.261739,332.600316 761.492646,334.199052 L675.474923,383.861408 C672.705831,385.460144 671,388.41473 671,391.612202 L671,490.936913 C671,494.134386 672.705831,497.088972 675.474923,498.687708 L761.492646,548.350064 C764.261739,549.9488 767.673401,549.9488 770.442493,548.350064 L856.460216,498.687708 C859.229309,497.088972 860.93514,494.134386 860.93514,490.936913 L860.93514,391.612202 C860.93514,388.41473 859.229309,385.460144 856.460216,383.861408 L770.442493,334.199052 Z" />
              <path d="M770.442493,995.199052 C767.673401,993.600316 764.261739,993.600316 761.492646,995.199052 L675.474923,1044.86141 C672.705831,1046.46014 671,1049.41473 671,1052.6122 L671,1151.93691 C671,1155.13439 672.705831,1158.08897 675.474923,1159.68771 L761.492646,1209.35006 C764.261739,1210.9488 767.673401,1210.9488 770.442493,1209.35006 L856.460216,1159.68771 C859.229309,1158.08897 860.93514,1155.13439 860.93514,1151.93691 L860.93514,1052.6122 C860.93514,1049.41473 859.229309,1046.46014 856.460216,1044.86141 L770.442493,995.199052 Z" />
              <path d="M196.407417,995.32019 C193.638324,993.721454 190.226662,993.721454 187.45757,995.32019 L101.439847,1044.98255 C98.6707545,1046.58128 96.9649233,1049.53587 96.9649233,1052.73334 L96.9649233,1152.05805 C96.9649233,1155.25552 98.6707545,1158.21011 101.439847,1159.80885 L187.45757,1209.4712 C190.226662,1211.06994 193.638324,1211.06994 196.407417,1209.4712 L282.42514,1159.80885 C285.194232,1158.21011 286.900063,1155.25552 286.900063,1152.05805 L286.900063,1052.73334 C286.900063,1049.53587 285.194232,1046.58128 282.42514,1044.98255 L196.407417,995.32019 Z" />
              <path d="M770.442493,665.199052 C767.673401,663.600316 764.261739,663.600316 761.492646,665.199052 L675.474923,714.861408 C672.705831,716.460144 671,719.41473 671,722.612202 L671,821.936913 C671,825.134386 672.705831,828.088972 675.474923,829.687708 L761.492646,879.350064 C764.261739,880.9488 767.673401,880.9488 770.442493,879.350064 L856.460216,829.687708 C859.229309,828.088972 860.93514,825.134386 860.93514,821.936913 L860.93514,722.612202 C860.93514,719.41473 859.229309,716.460144 856.460216,714.861408 L770.442493,665.199052 Z" />
              <path d="M579.626804,666.802212 C576.857712,665.203476 573.44605,665.203476 570.676957,666.802212 L484.659234,716.464568 C481.890142,718.063304 480.184311,721.01789 480.184311,724.215363 L480.184311,823.540074 C480.184311,826.737546 481.890142,829.692132 484.659234,831.290869 L570.676957,880.953224 C573.44605,882.55196 576.857712,882.55196 579.626804,880.953224 L665.644527,831.290869 C668.413619,829.692132 670.119451,826.737546 670.119451,823.540074 L670.119451,724.215363 C670.119451,721.01789 668.413619,718.063304 665.644527,716.464568 L579.626804,666.802212 Z" />
              <path d="M2016.0831,830.255484 C2013.31401,828.656748 2009.90234,828.656748 2007.13325,830.255484 L1921.11553,879.91784 C1918.34644,881.516576 1916.6406,884.471162 1916.6406,887.668635 L1916.6406,986.993346 C1916.6406,990.190818 1918.34644,993.145404 1921.11553,994.74414 L2007.13325,1044.4065 C2009.90234,1046.00523 2013.31401,1046.00523 2016.0831,1044.4065 L2102.10082,994.74414 C2104.86991,993.145404 2106.57574,990.190818 2106.57574,986.993346 L2106.57574,887.668635 C2106.57574,884.471162 2104.86991,881.516576 2102.10082,879.91784 L2016.0831,830.255484 Z" />
              <path d="M2111.91457,995.690867 C2109.14548,994.092131 2105.73382,994.092131 2102.96472,995.690867 L2016.947,1045.35322 C2014.17791,1046.95196 2012.47208,1049.90655 2012.47208,1053.10402 L2012.47208,1152.42873 C2012.47208,1155.6262 2014.17791,1158.58079 2016.947,1160.17952 L2102.96472,1209.84188 C2105.73382,1211.44062 2109.14548,1211.44062 2111.91457,1209.84188 L2197.93229,1160.17952 C2200.70139,1158.58079 2202.40722,1155.6262 2202.40722,1152.42873 L2202.40722,1053.10402 C2202.40722,1049.90655 2200.70139,1046.95196 2197.93229,1045.35322 L2111.91457,995.690867 Z" />
              <path d="M2111.91457,664.820102 C2109.14548,663.221365 2105.73382,663.221365 2102.96472,664.820102 L2016.947,714.482457 C2014.17791,716.081193 2012.47208,719.03578 2012.47208,722.233252 L2012.47208,821.557963 C2012.47208,824.755435 2014.17791,827.710021 2016.947,829.308758 L2102.96472,878.971113 C2105.73382,880.569849 2109.14548,880.569849 2111.91457,878.971113 L2197.93229,829.308758 C2200.70139,827.710021 2202.40722,824.755435 2202.40722,821.557963 L2202.40722,722.233252 C2202.40722,719.03578 2200.70139,716.081193 2197.93229,714.482457 L2111.91457,664.820102 Z" />
              <path d="M675.910486,1160.98977 C673.141393,1159.39103 669.729731,1159.39103 666.960639,1160.98977 L580.942916,1210.65212 C578.173824,1212.25086 576.467992,1215.20545 576.467992,1218.40292 L576.467992,1317.72763 C576.467992,1320.9251 578.173824,1323.87969 580.942916,1325.47843 L666.960639,1375.14078 C669.729731,1376.73952 673.141393,1376.73952 675.910486,1375.14078 L761.928209,1325.47843 C764.697301,1323.87969 766.403132,1320.9251 766.403132,1317.72763 L766.403132,1218.40292 C766.403132,1215.20545 764.697301,1212.25086 761.928209,1210.65212 L675.910486,1160.98977 Z" />
              <path d="M1632.44249,830.199052 C1629.6734,828.600316 1626.26174,828.600316 1623.49265,830.199052 L1537.47492,879.861408 C1534.70583,881.460144 1533,884.41473 1533,887.612202 L1533,986.936913 C1533,990.134386 1534.70583,993.088972 1537.47492,994.687708 L1623.49265,1044.35006 C1626.26174,1045.9488 1629.6734,1045.9488 1632.44249,1044.35006 L1718.46022,994.687708 C1721.22931,993.088972 1722.93514,990.134386 1722.93514,986.936913 L1722.93514,887.612202 C1722.93514,884.41473 1721.22931,881.460144 1718.46022,879.861408 L1632.44249,830.199052 Z" />
              <path d="M675.910486,829.989769 C673.141393,828.391033 669.729731,828.391033 666.960639,829.989769 L580.942916,879.652125 C578.173824,881.250861 576.467992,884.205447 576.467992,887.40292 L576.467992,986.727631 C576.467992,989.925103 578.173824,992.879689 580.942916,994.478425 L666.960639,1044.14078 C669.729731,1045.73952 673.141393,1045.73952 675.910486,1044.14078 L761.928209,994.478425 C764.697301,992.879689 766.403132,989.925103 766.403132,986.727631 L766.403132,887.40292 C766.403132,884.205447 764.697301,881.250861 761.928209,879.652125 L675.910486,829.989769 Z" />
              <path d="M1728.44249,995.199052 C1725.6734,993.600316 1722.26174,993.600316 1719.49265,995.199052 L1633.47492,1044.86141 C1630.70583,1046.46014 1629,1049.41473 1629,1052.6122 L1629,1151.93691 C1629,1155.13439 1630.70583,1158.08897 1633.47492,1159.68771 L1719.49265,1209.35006 C1722.26174,1210.9488 1725.6734,1210.9488 1728.44249,1209.35006 L1814.46022,1159.68771 C1817.22931,1158.08897 1818.93514,1155.13439 1818.93514,1151.93691 L1818.93514,1052.6122 C1818.93514,1049.41473 1817.22931,1046.46014 1814.46022,1044.86141 L1728.44249,995.199052 Z" />
              <path d="M1728.51637,1327.05411 C1725.74728,1325.45537 1722.33562,1325.45537 1719.56652,1327.05411 L1633.5488,1376.71647 C1630.77971,1378.3152 1629.07388,1381.26979 1629.07388,1384.46726 L1629.07388,1483.79197 C1629.07388,1486.98944 1630.77971,1489.94403 1633.5488,1491.54277 L1719.56652,1541.20512 C1722.33562,1542.80386 1725.74728,1542.80386 1728.51637,1541.20512 L1814.53409,1491.54277 C1817.30319,1489.94403 1819.00902,1486.98944 1819.00902,1483.79197 L1819.00902,1384.46726 C1819.00902,1381.26979 1817.30319,1378.3152 1814.53409,1376.71647 L1728.51637,1327.05411 Z" />
              <path d="M1249.43132,1161.12625 C1246.66223,1159.52751 1243.25057,1159.52751 1240.48148,1161.12625 L1154.46375,1210.78861 C1151.69466,1212.38734 1149.98883,1215.34193 1149.98883,1218.5394 L1149.98883,1317.86411 C1149.98883,1321.06158 1151.69466,1324.01617 1154.46375,1325.61491 L1240.48148,1375.27726 C1243.25057,1376.876 1246.66223,1376.876 1249.43132,1375.27726 L1335.44905,1325.61491 C1338.21814,1324.01617 1339.92397,1321.06158 1339.92397,1317.86411 L1339.92397,1218.5394 C1339.92397,1215.34193 1338.21814,1212.38734 1335.44905,1210.78861 L1249.43132,1161.12625 Z" />
              <path d="M1249.43132,830.255484 C1246.66223,828.656748 1243.25057,828.656748 1240.48148,830.255484 L1154.46375,879.91784 C1151.69466,881.516576 1149.98883,884.471162 1149.98883,887.668635 L1149.98883,986.993346 C1149.98883,990.190818 1151.69466,993.145404 1154.46375,994.74414 L1240.48148,1044.4065 C1243.25057,1046.00523 1246.66223,1046.00523 1249.43132,1044.4065 L1335.44905,994.74414 C1338.21814,993.145404 1339.92397,990.190818 1339.92397,986.993346 L1339.92397,887.668635 C1339.92397,884.471162 1338.21814,881.516576 1335.44905,879.91784 L1249.43132,830.255484 Z" />
              <path d="M1345.44249,995.199052 C1342.6734,993.600316 1339.26174,993.600316 1336.49265,995.199052 L1250.47492,1044.86141 C1247.70583,1046.46014 1246,1049.41473 1246,1052.6122 L1246,1151.93691 C1246,1155.13439 1247.70583,1158.08897 1250.47492,1159.68771 L1336.49265,1209.35006 C1339.26174,1210.9488 1342.6734,1210.9488 1345.44249,1209.35006 L1431.46022,1159.68771 C1434.22931,1158.08897 1435.93514,1155.13439 1435.93514,1151.93691 L1435.93514,1052.6122 C1435.93514,1049.41473 1434.22931,1046.46014 1431.46022,1044.86141 L1345.44249,995.199052 Z" />
              <path d="M388.910486,994.989769 C386.141393,993.391033 382.729731,993.391033 379.960639,994.989769 L293.942916,1044.65212 C291.173824,1046.25086 289.467992,1049.20545 289.467992,1052.40292 L289.467992,1151.72763 C289.467992,1154.9251 291.173824,1157.87969 293.942916,1159.47843 L379.960639,1209.14078 C382.729731,1210.73952 386.141393,1210.73952 388.910486,1209.14078 L474.928209,1159.47843 C477.697301,1157.87969 479.403132,1154.9251 479.403132,1151.72763 L479.403132,1052.40292 C479.403132,1049.20545 477.697301,1046.25086 474.928209,1044.65212 L388.910486,994.989769 Z" />
              <path d="M1345.51637,1327.05411 C1342.74728,1325.45537 1339.33562,1325.45537 1336.56652,1327.05411 L1250.5488,1376.71647 C1247.77971,1378.3152 1246.07388,1381.26979 1246.07388,1384.46726 L1246.07388,1483.79197 C1246.07388,1486.98944 1247.77971,1489.94403 1250.5488,1491.54277 L1336.56652,1541.20512 C1339.33562,1542.80386 1342.74728,1542.80386 1345.51637,1541.20512 L1431.53409,1491.54277 C1434.30319,1489.94403 1436.00902,1486.98944 1436.00902,1483.79197 L1436.00902,1384.46726 C1436.00902,1381.26979 1434.30319,1378.3152 1431.53409,1376.71647 L1345.51637,1327.05411 Z" />
              <path d="M866.105437,1161.12625 C863.336345,1159.52751 859.924682,1159.52751 857.15559,1161.12625 L771.137867,1210.78861 C768.368775,1212.38734 766.662944,1215.34193 766.662944,1218.5394 L766.662944,1317.86411 C766.662944,1321.06158 768.368775,1324.01617 771.137867,1325.61491 L857.15559,1375.27726 C859.924682,1376.876 863.336345,1376.876 866.105437,1375.27726 L952.12316,1325.61491 C954.892252,1324.01617 956.598083,1321.06158 956.598083,1317.86411 L956.598083,1218.5394 C956.598083,1215.34193 954.892252,1212.38734 952.12316,1210.78861 L866.105437,1161.12625 Z" />
              <path d="M866.442493,830.199052 C863.673401,828.600316 860.261739,828.600316 857.492646,830.199052 L771.474923,879.861408 C768.705831,881.460144 767,884.41473 767,887.612202 L767,986.936913 C767,990.134386 768.705831,993.088972 771.474923,994.687708 L857.492646,1044.35006 C860.261739,1045.9488 863.673401,1045.9488 866.442493,1044.35006 L952.460216,994.687708 C955.229309,993.088972 956.93514,990.134386 956.93514,986.936913 L956.93514,887.612202 C956.93514,884.41473 955.229309,881.460144 952.460216,879.861408 L866.442493,830.199052 Z" />
              <path d="M292.407417,829.32019 C289.638324,827.721454 286.226662,827.721454 283.45757,829.32019 L197.439847,878.982545 C194.670754,880.581281 192.964923,883.535868 192.964923,886.73334 L192.964923,986.058051 C192.964923,989.255523 194.670754,992.21011 197.439847,993.808846 L283.45757,1043.4712 C286.226662,1045.06994 289.638324,1045.06994 292.407417,1043.4712 L378.42514,993.808846 C381.194232,992.21011 382.900063,989.255523 382.900063,986.058051 L382.900063,886.73334 C382.900063,883.535868 381.194232,880.581281 378.42514,878.982545 L292.407417,829.32019 Z" />
              <path d="M961.936909,995.690867 C959.167816,994.092131 955.756154,994.092131 952.987062,995.690867 L866.969339,1045.35322 C864.200247,1046.95196 862.494415,1049.90655 862.494415,1053.10402 L862.494415,1152.42873 C862.494415,1155.6262 864.200247,1158.58079 866.969339,1160.17952 L952.987062,1209.84188 C955.756154,1211.44062 959.167816,1211.44062 961.936909,1209.84188 L1047.95463,1160.17952 C1050.72372,1158.58079 1052.42956,1155.6262 1052.42956,1152.42873 L1052.42956,1053.10402 C1052.42956,1049.90655 1050.72372,1046.95196 1047.95463,1045.35322 L961.936909,995.690867 Z" />
              <path d="M961.936909,3.07857017 C959.167816,1.479834 955.756154,1.479834 952.987062,3.07857017 L866.969339,52.7409257 C864.200247,54.3396618 862.494415,57.2942481 862.494415,60.4917205 L862.494415,159.816431 C862.494415,163.013904 864.200247,165.96849 866.969339,167.567226 L952.987062,217.229582 C955.756154,218.828318 959.167816,218.828318 961.936909,217.229582 L1047.95463,167.567226 C1050.72372,165.96849 1052.42956,163.013904 1052.42956,159.816431 L1052.42956,60.4917205 C1052.42956,57.2942481 1050.72372,54.3396618 1047.95463,52.7409257 L961.936909,3.07857017 Z" />
              <path d="M961.936909,664.820102 C959.167816,663.221365 955.756154,663.221365 952.987062,664.820102 L866.969339,714.482457 C864.200247,716.081193 862.494415,719.03578 862.494415,722.233252 L862.494415,821.557963 C862.494415,824.755435 864.200247,827.710021 866.969339,829.308758 L952.987062,878.971113 C955.756154,880.569849 959.167816,880.569849 961.936909,878.971113 L1047.95463,829.308758 C1050.72372,827.710021 1052.42956,824.755435 1052.42956,821.557963 L1052.42956,722.233252 C1052.42956,719.03578 1050.72372,716.081193 1047.95463,714.482457 L961.936909,664.820102 Z" />
              <path d="M2303.57751,333.949336 C2300.80842,332.3506 2297.39676,332.3506 2294.62767,333.949336 L2208.60994,383.611691 C2205.84085,385.210428 2204.13502,388.165014 2204.13502,391.362486 L2204.13502,490.687197 C2204.13502,493.884669 2205.84085,496.839256 2208.60994,498.437992 L2294.62767,548.100347 C2297.39676,549.699084 2300.80842,549.699084 2303.57751,548.100347 L2389.59524,498.437992 C2392.36433,496.839256 2394.07016,493.884669 2394.07016,490.687197 L2394.07016,391.362486 C2394.07016,388.165014 2392.36433,385.210428 2389.59524,383.611691 L2303.57751,333.949336 Z" />
              <path d="M2399.44249,1161.19905 C2396.6734,1159.60032 2393.26174,1159.60032 2390.49265,1161.19905 L2304.47492,1210.86141 C2301.70583,1212.46014 2300,1215.41473 2300,1218.6122 L2300,1317.93691 C2300,1321.13439 2301.70583,1324.08897 2304.47492,1325.68771 L2390.49265,1375.35006 C2393.26174,1376.9488 2396.6734,1376.9488 2399.44249,1375.35006 L2485.46022,1325.68771 C2488.22931,1324.08897 2489.93514,1321.13439 2489.93514,1317.93691 L2489.93514,1218.6122 C2489.93514,1215.41473 2488.22931,1212.46014 2485.46022,1210.86141 L2399.44249,1161.19905 Z" />
              <path d="M2686.28404,1328.01021 C2683.51495,1326.41148 2680.10328,1326.41148 2677.33419,1328.01021 L2591.31647,1377.67257 C2588.54738,1379.2713 2586.84154,1382.22589 2586.84154,1385.42336 L2586.84154,1484.74807 C2586.84154,1487.94555 2588.54738,1490.90013 2591.31647,1492.49887 L2677.33419,1542.16122 C2680.10328,1543.75996 2683.51495,1543.75996 2686.28404,1542.16122 L2772.30176,1492.49887 C2775.07085,1490.90013 2776.77668,1487.94555 2776.77668,1484.74807 L2776.77668,1385.42336 C2776.77668,1382.22589 2775.07085,1379.2713 2772.30176,1377.67257 L2686.28404,1328.01021 Z" />
              <path d="M2399.40899,168.513953 C2396.63989,166.915217 2393.22823,166.915217 2390.45914,168.513953 L2304.44142,218.176309 C2301.67232,219.775045 2299.96649,222.729631 2299.96649,225.927103 L2299.96649,325.251814 C2299.96649,328.449287 2301.67232,331.403873 2304.44142,333.002609 L2390.45914,382.664965 C2393.22823,384.263701 2396.63989,384.263701 2399.40899,382.664965 L2485.42671,333.002609 C2488.1958,331.403873 2489.90163,328.449287 2489.90163,325.251814 L2489.90163,225.927103 C2489.90163,222.729631 2488.1958,219.775045 2485.42671,218.176309 L2399.40899,168.513953 Z" />
              <path d="M2399.40899,830.255484 C2396.63989,828.656748 2393.22823,828.656748 2390.45914,830.255484 L2304.44142,879.91784 C2301.67232,881.516576 2299.96649,884.471162 2299.96649,887.668635 L2299.96649,986.993346 C2299.96649,990.190818 2301.67232,993.145404 2304.44142,994.74414 L2390.45914,1044.4065 C2393.22823,1046.00523 2396.63989,1046.00523 2399.40899,1044.4065 L2485.42671,994.74414 C2488.1958,993.145404 2489.90163,990.190818 2489.90163,986.993346 L2489.90163,887.668635 C2489.90163,884.471162 2488.1958,881.516576 2485.42671,879.91784 L2399.40899,830.255484 Z" />
              <path d="M2495.24046,333.949336 C2492.47136,332.3506 2489.0597,332.3506 2486.29061,333.949336 L2400.27289,383.611691 C2397.50379,385.210428 2395.79796,388.165014 2395.79796,391.362486 L2395.79796,490.687197 C2395.79796,493.884669 2397.50379,496.839256 2400.27289,498.437992 L2486.29061,548.100347 C2489.0597,549.699084 2492.47136,549.699084 2495.24046,548.100347 L2581.25818,498.437992 C2584.02727,496.839256 2585.7331,493.884669 2585.7331,490.687197 L2585.7331,391.362486 C2585.7331,388.165014 2584.02727,385.210428 2581.25818,383.611691 L2495.24046,333.949336 Z" />
              <path d="M2495.24046,995.690867 C2492.47136,994.092131 2489.0597,994.092131 2486.29061,995.690867 L2400.27289,1045.35322 C2397.50379,1046.95196 2395.79796,1049.90655 2395.79796,1053.10402 L2395.79796,1152.42873 C2395.79796,1155.6262 2397.50379,1158.58079 2400.27289,1160.17952 L2486.29061,1209.84188 C2489.0597,1211.44062 2492.47136,1211.44062 2495.24046,1209.84188 L2581.25818,1160.17952 C2584.02727,1158.58079 2585.7331,1155.6262 2585.7331,1152.42873 L2585.7331,1053.10402 C2585.7331,1049.90655 2584.02727,1046.95196 2581.25818,1045.35322 L2495.24046,995.690867 Z" />
              <path d="M2495.24046,664.820102 C2492.47136,663.221365 2489.0597,663.221365 2486.29061,664.820102 L2400.27289,714.482457 C2397.50379,716.081193 2395.79796,719.03578 2395.79796,722.233252 L2395.79796,821.557963 C2395.79796,824.755435 2397.50379,827.710021 2400.27289,829.308758 L2486.29061,878.971113 C2489.0597,880.569849 2492.47136,880.569849 2495.24046,878.971113 L2581.25818,829.308758 C2584.02727,827.710021 2585.7331,824.755435 2585.7331,821.557963 L2585.7331,722.233252 C2585.7331,719.03578 2584.02727,716.081193 2581.25818,714.482457 L2495.24046,664.820102 Z" />
            </g>
          </g>
        </g>
      </svg>
    </Parallax>
  </div>
);

const animated = false;

const StyledHexagons = styled(Hexagons)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0.025;

  ${animated
  ? css`
    path {
      animation: sparkle 2000ms infinite alternate;
      @keyframes sparkle { 0% { opacity: 0.25; } };

      ${Array.from(Array(77)).map((_, i) => css`
        &:nth-child(${i + 1}) {
          animation-delay: -${random(0, 3000)}ms;
        }
      `)}
    }
  `
  : css`
    path {
      ${Array.from(Array(77)).map((_, i) => css`
        &:nth-child(${i + 1}) {
          opacity: ${random(20, 100) / 100};
        }
      `)}
    }
  `}
`;

export default StyledHexagons;
