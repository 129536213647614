import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled, { injectGlobal, ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';

import { reset, antialias, noSelect } from '../utils';

import theme from '../theme';
import ogImage from '../assets/images/og-image.png';

require('typeface-montserrat');

// eslint-disable-next-line
injectGlobal`
  ${reset()};
  ${antialias()};
  ${noSelect()};

  ::selection {
    background: hsla(0, 0%, 0%, 0.05);
  }

  html, body {
    position: relative;
    overflow-x: hidden;
  }

  iframe#netlify-identity-widget {
    z-index: 9999999999999999 !important;
  }
`;

const App = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: ${themeGet('colors.white.base')};
  font-family: ${themeGet('fontFamilies.default')};
`;

const Layout = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet>
          <title>{data.site.siteMetadata.title}</title>
          <meta property="og:title" content="TheHive™" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Creative development hub." />
          <meta property="og:image" content={ogImage} />
          <meta property="og:url" content="https://thehive.rs" />
          <meta charSet="utf-8" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <ThemeProvider theme={{ mode: 'light' }}>
            <App>
              {props.children}
            </App>
          </ThemeProvider>
        </ThemeProvider>
      </React.Fragment>
    )}
  />
);

export default Layout;
