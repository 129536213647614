/**
 * Tag
 * ---
 */

import styled from 'styled-components';
import { themeGet } from 'styled-system';
import tag from 'clean-tag';

/**
 * Config
 * ------
 */

const conf = {
  font: {
    size: themeGet('fontSizes.0'),
    weight: themeGet('fontWeights.normal'),
  },

  margin: 10,
  padding: {
    horizontal: 32,
    vertical: 14,
  },

  color: {
    text: themeGet('colors.black.shade.3'),
    border: themeGet('colors.black.shade.4'),
  },
};

const Tag = styled(tag.div)`
  position: relative;
  display: inline-block;
  border-radius: 1000px;

  color: ${conf.color.text};
  border: 1px solid ${conf.color.border};
  font-size: ${conf.font.size};
  font-weight: ${conf.font.weight};
  margin-right: ${conf.margin}px;
  margin-bottom: ${conf.margin}px;
  padding: ${conf.padding.vertical}px ${conf.padding.horizontal}px;
`;

export default Tag;
