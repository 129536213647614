/**
 * Button
 * ------
 */

import styled from 'styled-components';
import { themeGet } from 'styled-system';
import themed from 'styled-theming';
import tag from 'clean-tag';

import { ease } from '../utils';

import arrow from '../assets/icons/arrow-light.svg';

/**
 * Config
 * ------
 */

const conf = {
  font: {
    size: themeGet('fontSizes.3'),
    weight: themeGet('fontWeights.bold'),
  },

  padding: {
    horizontal: 50,
    vertical: 20,
  },

  color: themeGet('colors.white.base'),

  background: themed('mode', {
    dark: themeGet('colors.brand.gradient'),
    light: themeGet('colors.brand.gradient'),
    color: themeGet('colors.black.shade.0'),
  }),

  transition: {
    opacity: 0.85,
    duration: 300,
  },
};

/**
 * Button
 * ------
 * Main component.
 */

const Button = styled(tag)`
  position: relative;
  display: inline-block;
  border-radius: 1000px;
  cursor: pointer;

  background: ${conf.background};
  color: ${conf.color};
  font-size: ${conf.font.size};
  font-weight: ${conf.font.weight};
  padding: ${conf.padding.vertical}px ${conf.padding.horizontal}px;

  transition: opacity ${conf.transition.duration}ms;
  &:hover { opacity: ${conf.transition.opacity}; }

  &:before {
    content: attr(data-text);
    display: inline-block;
    margin-right: -5px;
    transition: transform 800ms ${ease('out', 'circ')};
  }

  &:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: -5px;
    background: url(${arrow}) no-repeat center;
    background-size: contain;
    opacity: 0;
    transition: transform 800ms ${ease('out', 'circ')}, opacity 300ms;
  }

  &:hover {
    &:before { transform: translateX(-10px); }
    &:after { transform: translateX(10px); opacity: 1; }
  }
`;

export default Button;
