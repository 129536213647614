import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';
import tag from 'clean-tag';

import Block from '../components/Block';
import Sitewidth from '../components/Sitewidth';
import Logo from '../components/Logo';
import Text from '../components/Text';
import Button from '../components/Button';

import Hexagons from '../components/Hexagons';

import hexagonBase from '../assets/graphics/hexagon-color-base.svg';
import bee from '../assets/logos/bee.svg';

const conf = {
  dxbee: true,
};

const Hexagon = styled(tag)`
  position: absolute;
  pointer-events: none;
  width: 800px;
  height: 800px;
  top: -200px;
  left: -220px;
  background: url(${hexagonBase}) no-repeat bottom right;
  background-size: contain;

  @media (max-width: ${themeGet('breakpoints.1')}) { left: -320px; }
`;

const Bee = styled(tag)`
  width: 60px;
  height: 60px;
  background: url(${bee}) no-repeat bottom right;
  background-size: contain;
  opacity: 0.25;
  margin-bottom: 10px;
  margin-right: -10px;

  @media (max-width: ${themeGet('breakpoints.1')}) { display: none; }
`;

const Copyright = styled(Block)`
  opacity: 0.5;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    opacity: 0.75;

    ${Text} {
      color: white;
    }
  }

  p {
    white-space: nowrap;
  }
`;

const Footer = props => (
  <Block pt={props.simplified && [7, 8, 9]}>
    {!props.simplified && <Hexagons />}
    <Sitewidth>
      {!props.simplified
        && (
          <Block alignItems="center" textAlign="center" my={[7, 8]}>
            <Text is="h2" fontSize={[7, 8]} mb={[2, 3]} fontWeight="bold">
              Want to join us?
            </Text>
            <Text is="p" fontSize={[2, 3]} mb={5} fontWeight="light">
              We’re always looking for awesome people!
            </Text>
            <Button
              data-text="Apply"
              is={GatsbyLink}
              to="/careers/"
            />
          </Block>
        )
      }
      <Block
        flexDirection={['column', null, 'row']}
        alignItems={['flex-start', null, 'flex-end']}
        justifyContent="space-between"
      >
        <Block>
          <ThemeProvider theme={{ mode: 'color' }}>
            <Block px={[0, null, 6]} py={[4, 5, 6]} mt={[7, 6, 5, 4]}>
              <Hexagon />
              <Logo size="small" is={GatsbyLink} to="/" />
              <Block my={[4, 5, 6]} width="auto">
                <Text fontSize={1}>Feel free to drop us a line.</Text>
                <Text
                  is="a"
                  href="mailto:hello@thehive.rs"
                  fontSize={3}
                  fontWeight="bold"
                  color="black.shade.0"
                >hello [at] thehive.rs
                </Text>
              </Block>
              <Block width="auto">
                <Text fontSize={1}>
                  Baba Višnjina 45 Apt 5,<br />
                  11111 Belgrade,<br />
                  Serbia
                </Text>
              </Block>
            </Block>
          </ThemeProvider>
        </Block>
        <Copyright width="auto" pt={[4, 3]} pb={[5, 4]} alignItems="flex-end">
          {conf.dxbee && <Bee />}
          <Text fontSize={0} textAlign={['left', null, 'right']} >
            {conf.dxbee &&
              <a
                href="http://dxbee.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Part of DXBee™ Media Group.
              </a>
            }<br />
            © {new Date().getFullYear()}, TheHive™. All Rights Reserved.<br />
            Trademarks belong to their respective owners.
          </Text>
        </Copyright>
      </Block>
    </Sitewidth>
  </Block>
);

export default Footer;
